<template>
  <common-layout>
    <div class="top">
      <div class="header">
        <img alt="logo" class="logo" :src="pcLogo" />
      </div>
    </div>
    <div class="section">
      <a-form @submit="onSubmit" :form="form">
        <a-tabs
          size="large"
          :tabBarStyle="{ textAlign: 'center' }"
          style="padding: 0 2px"
        >
          <a-tab-pane tab="忘记密码" key="1">
            <a-form-item>
              <a-input
                autocomplete="new-password"
                size="large"
                placeholder="请输入绑定手机号"
                :max-length="11"
                v-decorator="[
                  'account',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入绑定手机号',
                        whitespace: true,
                      },
                      {
                        message: '请输入正确手机号',
                        pattern: /^1[3456789]\d{9}$/,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input-search
                autocomplete="new-password"
                size="large"
                placeholder="请输入验证码"
                :max-length="6"
                v-decorator="[
                  'captcha',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入验证码',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
                @search="sendSms"
              >
                <a-icon slot="prefix" type="mobile" />
                <a-button
                  slot="enterButton"
                  style="width: 100px; font-size: 14px"
                  :disabled="sendSmsLoading"
                >
                  {{ sendSmsTitle }}
                </a-button>
              </a-input-search>
            </a-form-item>
            <a-form-item>
              <a-input
                autocomplete="new-password"
                size="large"
                placeholder="请输入新密码"
                type="password"
                :max-length="32"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入新密码',
                        whitespace: true,
                      },
                      {
                        message: '密码长度为6-32位',
                        min: 6,
                        max: 32,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
        <a-form-item>
          <a-button
            :loading="loading"
            style="width: 100%"
            size="large"
            htmlType="submit"
            type="primary"
          >
            确定
          </a-button>
          <a-button
            style="width: 100%; margin-top: 12px"
            size="large"
            @click="$router.back()"
          >
            返回登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { setting, getSmsKey, sendSms, resetPassword } from "@/api/login";

export default {
  name: "ForgetPwd",
  components: { CommonLayout },
  data() {
    return {
      pcLogo: "",
      loading: false,
      form: this.$form.createForm(this),
      sendSmsTitle: "获取验证码",
      sendSmsLoading: false,
      countDownSecond: 120,
      timer: null,
    };
  },
  mounted() {
    setting().then((res) => {
      if (res.data.status == 200) {
        this.pcLogo = res.data.data.setting.pc_logo;
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 发送验证码
    async sendSms() {
      this.sendSmsLoading = true;
      try {
        let { account } = await this.form.validateFields(["account"]);
        let res = await getSmsKey();
        if (res.data.status != 200) {
          this.$message.error(res.data.msg);
          throw new Error(res.data.msg);
        }
        let res2 = await sendSms({
          phone: account,
          key: res.data.data.key,
        });
        if (res2.data.status != 200) {
          this.$message.error(res2.data.msg);
          throw new Error(res2.data.msg);
        }
        this.$message.success("发送成功");
        this.timer = setInterval(() => {
          this.countDownSecond = this.countDownSecond - 1;
          if (this.countDownSecond > 0) {
            this.sendSmsTitle = `${this.countDownSecond}s`;
          } else {
            this.countDownSecond = 120;
            this.sendSmsTitle = "获取验证码";
            this.sendSmsLoading = false;
            clearInterval(this.timer);
          }
        }, 1000);
      } catch (err) {
        this.sendSmsLoading = false;
      }
    },
    // 重置密码
    async onSubmit(e) {
      e.preventDefault();
      try {
        let form = await this.form.validateFields();
        let res = await resetPassword(form);
        if (res.data.status != 200) {
          this.$message.error(res.data.msg);
          throw new Error(res.data.msg);
        }
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.$message.success("密码重置成功");
        this.$router.back();
      } catch (err) {
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  text-align: center;
  .header {
    height: 44px;
    line-height: 44px;
    .logo {
      vertical-align: top;
    }
  }
}
.section {
  width: 368px;
  margin: 24px auto 0;
  @media screen and (max-width: 576px) {
    width: 95%;
  }
  @media screen and (max-width: 320px) {
    .captcha-button {
      font-size: 14px;
    }
  }
}
</style>
